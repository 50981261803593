
import Vue from 'vue';
import { FormType } from '../models/survey';
import { SurveyService } from '../services/survey';
interface LanguageChoice {
  text: string;
  id: string;
}
export default Vue.extend({
  computed: {
    activeLanguage(): LanguageChoice {
      const id = this.$store.getters.language;
      return this.languages.filter(l => l.id === id)[0];
    },
    surveyHasMultipleLanguages(): boolean {
      const surveyTypes = [FormType.PITCountObservation, FormType.PITCountInterview];
      for (let surveyType of surveyTypes) {
        const surveyStructure = this.$store.getters.surveyStructure(surveyType);
        if (
          surveyStructure &&
          SurveyService.hasMultipleLanguages(surveyStructure)
        ) {
          return true;
        }
      }
      return false;
    },
    languages(): LanguageChoice[] {
      const surveyTypes = [FormType.PITCountObservation, FormType.PITCountInterview];
      let languages = [] as string[];
      for (let type of surveyTypes) {
        const surveyStructure = this.$store.getters.surveyStructure(type);
        if (surveyStructure) {
          languages = languages.concat(
            SurveyService.getLanguages(surveyStructure)
          );
        }
      }
      return languages.map((l: string) => {
        const text = l === 'default' ? 'English' : l === 'es' ? 'Spanish' : l;
        return {
          id: l,
          text,
        };
      });
    },
  },
  methods: {
    languageSelected(a: LanguageChoice) {
      this.$store.commit('language', a.id);
    },
  },
});
