
import Vue from 'vue';
import StartSurveyButtons from './StartSurveyButtons.vue';
import Language from '@/components/Language.vue';
import LocationToggle from './LocationToggle.vue';
import LoggedIn from './LoggedIn.vue';
import ShelterDropdown, {
  ShelterOrOther,
} from '@/components/ShelterSelection/ShelterDropdown.vue';
export default Vue.extend({
  name: 'Start',
  components: {
    LocationToggle,
    Language,
    StartSurveyButtons,
    LoggedIn,
    ShelterDropdown,
  },
  computed: {
    showShelters(): boolean {
      return !!this.$route.query.sheltered;
    },
    shelterSelected(): boolean {
      return !!this.$store.getters.shelter?.name;
    },
    title() {
      if (this.$store.getters.hasObservation) return 'Choose Survey Type';
      return 'Configuration Options';
    },
    text() {
      if (this.$store.getters.hasObservation)
        return 'Are you able to have a conversation with the person or do you need to conduct an observation survey?';
      return 'Thank you for helping with the PIT count!';
    },
  },
});
