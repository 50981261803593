
import Vue from 'vue';
export default Vue.extend({
  methods: {
    customLocationToggleChanged(value: boolean) {
      this.$store.commit('customLocationToggleChanged', value);
    },
    customLocationChanged(value: string) {
      this.$store.commit('customLocation', value);
    }
  },
});
