
import { RegionMap, Shelter, ShelterSetWithId } from '@/models/count';
import { Team } from '@/models/Organization';
import Vue from 'vue';
export type ShelterOrOther = Shelter | { name: 'Other' };
export default Vue.extend({
  props: {
    value: {},
  },
  computed: {
    hasRegion() {
      const regionMap: RegionMap = this.$store.getters.regionMap;
      return !!regionMap;
    },
    hint() {
      const team: Team = this.$store.getters.team;

      if (!this.hasRegion) return '';
      if (!team) return 'All shelters';
      else return `Shelters in ${team.properties.name}`;
    },
    shelterList(): ShelterOrOther[] {
      return [...this.shelters, { name: 'Other' }];
    },
    shelters(): Shelter[] {
      const shelters: ShelterSetWithId = this.$store.getters.shelters;
      if (!shelters) return [];

      const team: Team = this.$store.getters.team;
      if (!team) return shelters.shelters;

      const teamName = team.properties.name;
      const count = this.$store.getters.count;
      return shelters.shelters.filter((s: Shelter) => s.region === teamName);
    },
  },
});
