
import Vue, { PropType } from 'vue';
import { FormType } from '@/models/survey';
import { ShelterSetWithId } from '@/models/count';

export default Vue.extend({
  name: 'StartSurveyButtons',
  props: {
    shelter: {
      type: Object as PropType<ShelterSetWithId>,
    },
    enabled: {
      type: Boolean,
    },
  },
  computed: {
    showObservationButton() {
      if (!this.$store.getters.hasObservation) return false;
      const sheltered = !!this.$route.query.sheltered;
      return !sheltered;
    },
  },
  methods: {
    async startSurvey(which: number) {
      const surveyTypes = [FormType.PITCountObservation, FormType.PITCountInterview];
      const type = surveyTypes[which];
      const currentLanguage = this.$store.getters.language;
      const language = currentLanguage ? currentLanguage : 'default';
      if (!this.shelter)
        this.$router.push({ path: `/new/${type}/${language}` });
      else
        this.$router.push({
          path: `/new/${type}/${language}`,
          query: { shelter: this.shelter.id },
        });
    },
  },
});
